import classnames from 'classnames';
import idx from 'idx';
import Router from 'next/router';
import { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

import NoImagePlaceholder from 'components/NoImagePlaceholder/NoImagePlaceholder';
import { ProductCardThumbnail } from 'components/ProductGrid/ProductCard/ProductCardThumbnail/ProductCardThumbnail';
import CleanTag from 'components/Tags/CleanTag/CleanTag';
import BookmarkButton from 'explorer/components/BookmarkButton/BookmarkButton';

import { DEFAULT_HEIGHT_ASPECT_RATIO, resizeImage } from 'lib/image';
import { ProductLink } from 'lib/links';
import { ProductLinkProps } from 'lib/links/_productLink';
import { pageRoutes } from 'lib/routes';
import { hasCleanLabel } from 'lib/variant/utils';

import { Image, SelectedOption } from 'data/graphql/types';
import { BookmarkByProductSid } from 'types/app';

import styles from './ProductCardImage.module.scss';

import { ProductCardData, ProductThumbnailClickHandler } from '../types';

export type ProductCardImageProps = {
  bookmarkSelectedOptions: SelectedOption[];
  bookmarksByProductSid: BookmarkByProductSid[];
  handleTrackingProductOnClick: () => void;
  hideBookmarkButton?: boolean;
  image?: Image;
  inStock: boolean;
  isLazyLoad?: boolean;
  isMouseOver?: boolean;
  onProductLinkClick?: () => void;
  onProductThumbnailClick?: ProductThumbnailClickHandler;
  price?: number;
  productCardData: ProductCardData;
  productCardIndex: number;
  productLinkProps: ProductLinkProps;
  variantSid?: string;
};

const ProductCardImage = ({
  bookmarkSelectedOptions,
  bookmarksByProductSid,
  handleTrackingProductOnClick,
  hideBookmarkButton,
  image,
  inStock,
  isLazyLoad = false,
  isMouseOver,
  onProductLinkClick,
  onProductThumbnailClick,
  price,
  productCardData,
  productCardIndex,
  productLinkProps,
  variantSid,
}: ProductCardImageProps): ReactElement => {
  const {
    brand,
    categories,
    dimensions,
    filterables,
    productSid,
    title,
  } = productCardData;

  const { inView, ref } = useInView({
    rootMargin: '0px 0px 100px 0px',
    triggerOnce: true,
  });

  const renderCleanBeautyLabel = () => {
    if (!filterables) {
      return null;
    }

    return hasCleanLabel(filterables) ? (
      <div className={styles.responsibleLabel}>
        <CleanTag hideText />
      </div>
    ) : null;
  };

  const rawCardImageUrl = idx(image, _ => _.url);
  const imageWidth = 400;
  const imageHeight = Math.floor(imageWidth * DEFAULT_HEIGHT_ASPECT_RATIO);
  const cardImageUrl =
    rawCardImageUrl &&
    resizeImage({
      categories,
      height: imageHeight,
      url: rawCardImageUrl,
      width: imageWidth,
    });

  const showThumbnail =
    inView && Router.pathname === pageRoutes.liveShopLanding.internalUrl();

  return (
    <>
      <ProductLink {...productLinkProps}>
        <a
          className={styles.anchor}
          href="placeholder"
          onClick={() => {
            handleTrackingProductOnClick();
            onProductLinkClick?.();
          }}
          ref={ref}
        >
          {renderCleanBeautyLabel()}
          <div
            className={classnames(styles.imageContainer, {
              [styles.border]: isMouseOver,
            })}
          >
            {!inStock && (
              <div className={styles.outOfStockOverlay}>
                <div className={styles.outOfStockText}>Out of Stock</div>
              </div>
            )}

            {cardImageUrl ? (
              <img
                alt={`${brand} ${title} product`}
                className={styles.image}
                src={inView || !isLazyLoad ? cardImageUrl : undefined}
              />
            ) : (
              <NoImagePlaceholder className={styles.noImagePlaceholder} />
            )}
            {showThumbnail && (
              <ProductCardThumbnail
                explorer={productCardData.topPostData}
                onProductThumbnailClick={onProductThumbnailClick}
                productCardData={productCardData}
              />
            )}
          </div>
        </a>
      </ProductLink>
      {!hideBookmarkButton && (
        <div className={styles.bookmarkButtonContainer}>
          <BookmarkButton
            bookmarksByProductSid={bookmarksByProductSid}
            classNameRoot={styles.bookmarkButton}
            isIcon
            price={price}
            productCardIndex={productCardIndex}
            productData={{
              brand,
              categories,
              productOptions: dimensions,
              productSid,
              title,
            }}
            selectedOptions={bookmarkSelectedOptions}
            variantSid={variantSid}
          />
        </div>
      )}
    </>
  );
};

export default ProductCardImage;
