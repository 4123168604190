import classnames from 'classnames';
import max from 'lodash/max';

import { ExperimentName } from 'lib/experiment/experimentConfig';
import { useExperiments } from 'lib/experiment/index';
import textFormatter from 'lib/textFormatter';
import {
  getDiscountPercentageMessage,
  removeZeroDecimals,
} from 'lib/utils/price';

import {
  ProductPriceRanges,
  ProductVariantPrices,
} from 'components/ProductGrid/ProductCard/types';

import styles from './PriceRangeInfo.module.scss';

type PriceRangeInfoProps = {
  compareAtPriceClassName?: string;
  currencyCode?: string; // TODO: CH[950] Force selection of currencyCode.
  hasSelectedColorOption: boolean;
  priceClassName?: string;
  productPriceRanges: ProductPriceRanges;
  selectedVariantPrices: ProductVariantPrices;
};

/*
  This component shows the price range, as well as the price range in sales
  prices if they exist
 */

const PriceRangeInfo = ({
  compareAtPriceClassName,
  currencyCode = 'USD', // TODO: CH[950] Force selection of currencyCode.
  hasSelectedColorOption,
  priceClassName,
  productPriceRanges = {
    compareAtPriceRange: { maxPrice: 0, minPrice: 0 },
    priceRange: { maxPrice: 0, minPrice: 0 },
  },
  selectedVariantPrices = {
    compareAtPrice: 0,
    price: 0,
  },
}: PriceRangeInfoProps) => {
  const {
    experiments: { enablePercentageDiscountMessage } = {},
  } = useExperiments(ExperimentName.enablePercentageDiscountMessage);
  const {
    compareAtPriceRange: {
      maxPrice: compareAtMaxPrice,
      minPrice: compareAtMinPrice,
    },
    priceRange: { maxPrice, minPrice },
  } = productPriceRanges;
  const {
    compareAtPrice: selectedVariantCompareAtPrice,
    price: selectedVariantPrice,
  } = selectedVariantPrices;

  if (!minPrice || !maxPrice) {
    return null;
  }

  const hasDiscount =
    compareAtMinPrice !== undefined &&
    compareAtMaxPrice !== undefined &&
    (maxPrice < compareAtMaxPrice || minPrice < compareAtMinPrice);

  const hasPriceRange =
    minPrice !== 0 && maxPrice !== 0 && minPrice !== maxPrice;
  const hasCompareAtPriceRange =
    compareAtMinPrice !== undefined &&
    compareAtMaxPrice !== undefined &&
    (compareAtMinPrice !== compareAtMaxPrice ||
      (compareAtMaxPrice !== 0 && compareAtMaxPrice < maxPrice));

  const variantHasDiscount =
    selectedVariantPrice !== undefined &&
    selectedVariantCompareAtPrice !== undefined &&
    selectedVariantPrice < selectedVariantCompareAtPrice;

  if (selectedVariantPrice && hasSelectedColorOption) {
    return (
      <div className={styles.root}>
        <span className={styles.srOnly}>
          {hasDiscount ? '; new Price: ' : '; price: '}
        </span>
        <span
          className={classnames(
            styles.price,
            { [styles.isDiscount]: variantHasDiscount },
            priceClassName
          )}
        >
          {removeZeroDecimals(
            textFormatter.formatCurrency(selectedVariantPrice, currencyCode)
          )}
        </span>
        {variantHasDiscount && (
          <>
            <span className={styles.srOnly}>; old price:</span>
            <del
              className={classnames(
                styles.compareAtPrice,
                compareAtPriceClassName
              )}
            >
              {removeZeroDecimals(
                textFormatter.formatCurrency(
                  selectedVariantCompareAtPrice,
                  currencyCode
                )
              )}
            </del>
            {enablePercentageDiscountMessage && (
              <span className={styles.discountPercentage}>
                {getDiscountPercentageMessage(
                  selectedVariantCompareAtPrice,
                  selectedVariantPrice,
                  !!(hasPriceRange || hasCompareAtPriceRange)
                )}
              </span>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <span className={styles.srOnly}>
        {hasDiscount ? '; new Price: ' : '; price: '}
      </span>
      <div
        className={classnames(
          styles.price,
          {
            [styles.isDiscount]: hasDiscount,
            [styles.multiLine]:
              (hasPriceRange || hasCompareAtPriceRange) &&
              !selectedVariantPrice,
          },
          priceClassName
        )}
      >
        {removeZeroDecimals(
          textFormatter.formatCurrency(minPrice, currencyCode)
        )}
        {hasPriceRange &&
          ` - ${removeZeroDecimals(
            textFormatter.formatCurrency(maxPrice, currencyCode)
          )}`}
      </div>
      {hasDiscount && (
        <>
          <span className={styles.srOnly}>; old price:</span>
          <del
            className={classnames(
              styles.compareAtPrice,
              compareAtPriceClassName
            )}
          >
            {removeZeroDecimals(
              textFormatter.formatCurrency(compareAtMinPrice, currencyCode)
            )}
            {hasCompareAtPriceRange &&
              ` - ${removeZeroDecimals(
                textFormatter.formatCurrency(
                  max([compareAtMaxPrice, maxPrice])!,
                  currencyCode
                )
              )}`}
          </del>
          {enablePercentageDiscountMessage && (
            <span className={styles.discountPercentage}>
              {getDiscountPercentageMessage(
                max([compareAtMaxPrice, minPrice]),
                minPrice,
                !!(hasPriceRange || hasCompareAtPriceRange)
              )}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default PriceRangeInfo;
